import { Tooltip } from 'antd';

interface LanguageFlagProps {
  language: string;
  tooltip: string;
}

export default function LanguageFlag({ language, tooltip }: Readonly<LanguageFlagProps>) {
  if (!language) return <></>;

  return (
    <Tooltip placement="top" title={tooltip}>
      <span
        className="language-container badge"
      >
        {language}
      </span>
    </Tooltip>
  );
}