import { isEmpty } from 'lodash';
import type { QuillOptionsStatic } from 'quill';
import QuillBetterTablePlus from 'quill-better-table-plus';

// get config for Quill based on passed options
export const getQuillConfig = (config: Partial<QuillOptionsStatic>): QuillOptionsStatic => ({
  theme: 'snow',
  ...config,
  modules: {
    table: false,
    'better-table-plus': {
      operationMenu: {
        color: false
      }
    },
    keyboard: {
      bindings: QuillBetterTablePlus.keyboardBindings
    },
    ...config?.modules
  }
});

// helper to update text format
export const applyQuillFormat = (text: string, align: boolean) => {
  if (isEmpty(text)) return text;

  const formatted = text.replace('&amp;', '&');

  // Keep paragraph structure for alignment
  if (align) {
    return formatted
      .replace(/class="ql-align-center"/g, 'style="text-align: center;"')
      .replace(/class="ql-align-right"/g, 'style="text-align: right;"')
      .replace(/&nbsp;/g, ' ')
      .replace(/( )*(contenteditable|spellcheck|lang)="[a-zA-Z]*"/g, '');
  }

  // Replace all paragraphs with break rules
  return formatted
    .replace(/&nbsp;/g, ' ')
    .replace(/<p[^>]*><br><\/p>/g, '<br/>')
    .replace(/<p[^>]*>/g, '')
    .replace(/<\/p>$/g, '')
    .replace(/<\/p>/g, '<br/>')
    .replace(/<br>$/g, '');
};

/**
 * Returns true if string is empty or only a new line.
 * Quill will add an empty line even if all text is removed.
 */
export const isQuillEmpty = (str: string) => isEmpty(str) || str === '\n';